/*==================
    Portfolio Area
==================*/

:focus {
	outline: 0px solid transparent;
}
.portfolio {
	position: relative;
	@extend %transition;
	min-height: 600px;
	width: 100%;
	padding-top: 35%;

	@media #{$laptop-device} {
		min-height: 500px;
	}
	@media #{$lg-layout} {
		min-height: 500px;
	}
	@media #{$md-layout} {
		min-height: 450px;
	}
	@media #{$sm-layout} {
		min-height: 400px;
	}
	.thumbnail-inner:focus {
		outline: none;
	}
	.portfolio-slick-activation {
		div:focus {
			outline: none;
			width: 2px;
		}
	}

	.thumbnail-inner {
		transition: transform 0.28s ease;
		z-index: 9;
		position: absolute;
		top: 0;
		left: 0;
		height: 100%;
		width: 100%;
		background-size: cover;
		background-position: center;
		overflow: hidden;
		border-radius: 5px;
		cursor: pointer;

		&::before {
			background-color: #f6004c;
			background-image: linear-gradient(#f6004c 10%, #000000 100%);
			position: absolute;
			content: "";
			top: 0;
			left: 0;
			width: 100%;
			height: 100%;
			z-index: 5;
			opacity: 0;
			@extend %transition;
		}
	}
	.thumbnail {
		position: absolute;
		top: 0;
		left: 0;
		height: 100%;
		width: 100%;
		background-size: cover;
		background-position: center;
		border-radius: 5px;
		background-color: #0a0a0a;
		transform: scale(1.13) translateZ(0);
		backface-visibility: hidden;
		transition: transform 0.28s ease;
		z-index: 4;
		&.image-1 {
			background-image: url(/assets/images/portfolio/SplitfareWeb.jpg);
		}

		&.image-2 {
			background-image: url(/assets/images/portfolio/InfluencersWeb.jpg);
		}

		&.image-3 {
			background-image: url(/assets/images/portfolio/SplitfareMobile.jpg);
		}

		&.image-4 {
			background-image: url(/assets/images/portfolio/portfolio-4.jpg);
		}

		&::after {
			position: absolute;
			top: 0;
			left: 0;
			height: 100%;
			width: 100%;
			content: " ";
			z-index: 1;
			display: block;
			border-radius: 5px;
			background: linear-gradient(rgba(0, 0, 0, 0) 0%, #000 120%);
			@extend %transition;
		}
	}

	.content {
		position: absolute;
		bottom: 40px;
		left: 40px;
		right: 40px;
		max-width: 100% !important;
		z-index: 10;
		padding: 0;
		@media #{$lg-layout} {
			left: 20px;
			right: 20px;
		}
		@media #{$md-layout} {
			left: 20px;
			right: 20px;
		}
		@media #{$sm-layout} {
			left: 20px;
			right: 20px;
		}
		.inner {
			p {
				color: #c6c9d8;
				font-size: 14px;
			}
			h4 {
				font-size: 24px;
				line-height: 36px;
				a {
					color: #ffffff;
				}
			}
			.portfolio-button {
				@extend %transition;
				margin-top: 35px;
				transition: 0.7s;
				@media #{$large-mobile} {
					margin-top: 25px;
				}
				a {
					&.rn-btn {
						color: #ffffff;
						border-color: rgba(255, 255, 255, 0.3);
						&:hover {
							border-color: $theme-color;
						}
					}
				}
			}
		}
	}
	&.text-center {
		.content {
			position: absolute;
			bottom: 40px;
			left: 40px;
			max-width: 80% !important;
			z-index: 10;
			padding: 0;
			right: 40px;
			margin: 0 auto;
			@media #{$lg-layout} {
				left: 20px;
				right: 20px;
			}
			@media #{$md-layout} {
				left: 15px;
				right: 15px;
			}
			@media #{$sm-layout} {
				left: 10px;
				right: 10px;
			}
		}
	}
	&:hover {
		.thumbnail-inner {
			transform: scale(1.08) translateZ(0);
			&::before {
				opacity: 0.85;
			}
		}
		.thumbnail {
			transform: scale(1) translateZ(0);
			&::after {
				background-color: rgba(25, 25, 25, 0.24);
			}
		}
		.bg-blr-image {
			opacity: 0;
			z-index: 9;
		}
	}
	&.portfolio-style--2 {
		.inner {
			margin: 0 20px;
		}
	}
}

// Minimal Portfolio

.portfolio-tilthover {
	.portfolio {
		margin-top: 30px;
		@media #{$sm-layout} {
			min-height: 400px;
		}
		@media #{$md-layout} {
			min-height: 400px;
		}
		@media #{$lg-layout} {
			min-height: 400px;
		}
		.thumbnail {
			position: absolute;
			top: 0;
			left: 0;
			height: 100%;
			width: 100%;
			background-size: cover;
			background-position: center;
			border-radius: 5px;
			background-color: #0a0a0a;
			background-image: url(/assets/images/portfolio/portfolio-static-01.jpg);
			transform: scale(1.13) translateZ(0);
			backface-visibility: hidden;
			transition: transform 0.28s ease;
			z-index: 4;
			@media #{$large-mobile} {
				transform: scale(1) translateZ(0);
			}
			&.image-1 {
				background-image: url(/assets/images/portfolio/portfolio-static-01.jpg);
			}
			&.image-2 {
				background-image: url(/assets/images/portfolio/portfolio-static-02.jpg);
			}
			&.image-3 {
				background-image: url(/assets/images/portfolio/portfolio-static-03.jpg);
			}
			&.image-4 {
				background-image: url(/assets/images/portfolio/portfolio-static-01.jpg);
			}
			&::after {
				display: none;
			}
		}

		.thumbnail-inner {
			@media #{$large-mobile} {
				transform: scale(1) translateZ(0);
				&::before {
					opacity: 0.85;
				}
			}
		}
		.bg-blr-image {
			position: absolute;
			top: 0;
			left: 0;
			height: 100%;
			width: 100%;
			background-size: cover;
			background-position: center;
			background-color: #0a0a0a;
			background-image: url(/assets/images/portfolio/portfolio-static-01.jpg);
			@extend %transition;
			transform: translateY(38px);
			opacity: 0;
			z-index: 1;
			@media #{$large-mobile} {
				opacity: 0;
				z-index: 9;
			}

			&.image-1 {
				background-image: url(/assets/images/portfolio/portfolio-static-01.jpg);
			}

			&.image-2 {
				background-image: url(/assets/images/portfolio/portfolio-static-03.jpg);
			}

			&.image-3 {
				background-image: url(/assets/images/portfolio/portfolio-static-03.jpg);
			}

			&.image-4 {
				background-image: url(/assets/images/portfolio/portfolio-static-01.jpg);
			}
		}

		.content {
			opacity: 0;
			@extend %transition;
			@media #{$large-mobile} {
				opacity: 1;
			}
		}
		&:hover {
			.content {
				opacity: 1;
			}
			.thumbnail-inner {
				transform: scale(1) translateZ(0);
				&::before {
					opacity: 0.85;
				}
			}
			.thumbnail {
				transform: scale(1) translateZ(0);
				&::after {
					background-color: rgba(25, 25, 25, 0.24);
				}
			}
			.bg-blr-image {
				opacity: 0;
				z-index: 9;
			}
		}
	}
}

.portfolio-sacousel-inner {
	.slick-list {
		margin-left: -200px;
		margin-right: -200px;
		@media #{$md-layout} {
			margin-left: 15px;
			margin-right: 15px;
		}
		@media #{$lg-layout} {
			margin-left: 15px;
			margin-right: 15px;
		}
		@media #{$sm-layout} {
			margin-left: 15px;
			margin-right: 15px;
		}
	}
}

.portfolio-sacousel-inner {
	.slick-slide {
		padding-left: 15px;
		padding-right: 15px;
		margin-bottom: -10px;
		@media #{$sm-layout} {
			padding-left: 30px;
			padding-right: 30px;
		}
	}
}

.portfolio-sacousel-inner {
	.slick-list {
		overflow: visible;
	}
}

.portfolio-sacousel-inner,
.rn-slick-dot {
	.slick-dots {
		bottom: -60px;
		@media #{$large-mobile} {
			bottom: -50px;
		}
		li {
			width: 30px;
			margin: 0;
			opacity: 0.35;
			position: relative;

			button {
				&::before {
					display: none;
				}
				&::after {
					background: #333;
					width: 100%;
					display: block;
					content: "";
					position: absolute;
					left: 0;
					width: 6px;
					height: 6px;
					@extend %transition;
					border-radius: 50%;
					margin-left: 11px;
					background-color: transparent;
					box-shadow: inset 0 0 0 5px #000;
				}
			}
			&.slick-active {
				opacity: 1;
				button {
					&::after {
						-webkit-transform: scale(2);
						transform: scale(2);
						box-shadow: inset 0 0 0 1px $theme-color;
						background-color: transparent;
						background-color: transparent !important;
					}
				}
			}
		}
	}
	// Dot Light
	&.dot-light {
		.slick-dots {
			li {
				button {
					&::after {
						background: #ffffff;
						box-shadow: inset 0 0 0 5px #ffffff;
					}
				}
				&.slick-active {
					opacity: 1;
					button {
						&::after {
							-webkit-transform: scale(2);
							transform: scale(2);
							box-shadow: inset 0 0 0 1px $theme-color;
							background-color: transparent;
							background-color: transparent !important;
						}
					}
				}
			}
		}
	}
}

/* Slick Arrow Button  */

.portfolio-area {
	overflow: hidden;
}

.portfolio-slick-activation {
	position: relative;
	button {
		&.slick-arrow {
			position: absolute;
			z-index: 3;
			background: transparent;
			font-size: 20px;
			width: 253px;
			height: 101%;

			@media #{$lg-layout} {
				width: 136px;
			}

			@media #{$md-layout} {
				width: 100px;
			}

			@media #{$sm-layout} {
				width: 100px;
				display: none !important;
			}

			&::before {
				line-height: 1;
				opacity: 0.75;
				font-size: 30px;
				position: relative;
				top: -14px;
				transition: 0.5s;
			}
			&.slick-prev {
				left: 0;
				background: -moz-linear-gradient(
					left,
					rgba(0, 0, 0, 1) 0%,
					rgba(0, 0, 0, 0) 100%
				);
				background: -webkit-linear-gradient(
					left,
					rgba(0, 0, 0, 1) 0%,
					rgba(0, 0, 0, 0) 100%
				);
				background: linear-gradient(
					to right,
					rgba(0, 0, 0, 1) 0%,
					rgba(0, 0, 0, 0) 100%
				);
				filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#000000', endColorstr='#00000000',GradientType=1 );
				&::before {
					content: url(/assets/images/icons/slick-arrow-left.png);
				}
				@media #{$md-layout} {
					display: none !important;
				}
				@media #{$lg-layout} {
					display: none !important;
				}
				@media #{$laptop-device} {
					display: none !important;
				}
			}
			&.slick-next {
				right: 0;
				background: -moz-linear-gradient(
					right,
					rgba(0, 0, 0, 1) 0%,
					rgba(0, 0, 0, 0) 100%
				);
				background: -webkit-linear-gradient(
					right,
					rgba(0, 0, 0, 1) 0%,
					rgba(0, 0, 0, 0) 100%
				);
				background: linear-gradient(
					to left,
					rgba(0, 0, 0, 1) 0%,
					rgba(0, 0, 0, 0) 100%
				);
				filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#000000', endColorstr='#00000000',GradientType=1 );
				&::before {
					content: url(/assets/images/icons/slick-arrow-right.png);
				}
				@media #{$md-layout} {
					display: none !important;
				}
				@media #{$lg-layout} {
					display: none !important;
				}
				@media #{$laptop-device} {
					display: none !important;
				}
			}

			&:hover {
				&.slick-prev {
					padding-right: 50px;

					@media #{$md-layout} {
						padding-right: 10px;
					}
					@media #{$sm-layout} {
						padding-right: 10px;
					}
				}
				&.slick-next {
					padding-left: 50px;
					@media #{$md-layout} {
						padding-right: 10px;
					}
					@media #{$sm-layout} {
						padding-right: 10px;
					}
				}
				&::before {
					opacity: 1;
				}
			}
		}
	}
}

/* Slick Gutter Space  */
.slick-space-gutter--15 {
	margin-left: -15px;
	margin-right: -15px;
	.slick-slide {
		padding-left: 15px;
		padding-right: 15px;
	}
	.portfolio {
		margin: 40px 0;
	}
}

.slickdot--20 {
	.slick-dots {
		bottom: -20px;
	}
}
