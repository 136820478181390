// Contact Styles

.contact-form--1 {
	label {
		display: block;
		margin-bottom: 0;
	}

	input,
	textarea {
		display: block;
		width: 100%;
		padding: 0 20px;
		border: 2px solid rgba(0, 0, 0, 0.1);
		border-radius: 5px;
		transition: all 0.3s ease;
		height: 50px;
		line-height: inherit;
		margin-bottom: 20px;
		outline: none;
		color: #1f1f25;
		font-size: 15px;
		letter-spacing: 0.1px;
		&:focus {
			border-color: $theme-color;
		}
	}
	textarea {
		height: unset;
		padding-top: 15px;
		padding-bottom: 15px;
	}

	.contact-separator {
		width: 100px;
		height: 2px;
		background: #f9004d;
		opacity: 0.75;
		display: block;
	}

	p.copyrights {
		font-size: 13px;
		@media #{$md-layout} {
			padding-top: 40px;
		}
		@media #{$sm-layout} {
			font-size: 13px !important;
			padding-top: 40px;
		}
	}

	button.rn-button-style--2[disabled],
	button.rn-button-style--2[disabled]:hover {
		box-shadow: none;
		transform: none;
		background: #f9004d61;
		color: #ffffff4f;
		border: 2px solid #f9004d40;
	}

	.button-wrapper {
		position: relative;
		display: inline-block;
	}

	.loader {
		position: absolute;
		top: 50%;
		left: 50%;
		transform: translate(-50%, -50%);
	}

	ul.socialmedia {
		justify-content: flex-start;
	}
	ul.socialmedia {
		> li {
			margin: 0 10px;
			> a {
				font-size: 32px;
				padding: 5px 0;
			}
		}
	}
}

/*==========================
    Contact Address
============================*/

.rn-address {
	margin-top: 30px;
	border: 1px solid #ebebeb;
	padding: 40px;
	border-radius: 10px;
	padding-top: 54px;
	background: #fff;
	padding-bottom: 50px;
	.icon {
		border: 1px solid #ebebeb;
		width: 78px;
		height: 78px;
		color: #f9004d;
		line-height: 73px;
		text-align: center;
		border-radius: 100%;
		background: #ffffff;
		font-size: 28px;
		position: absolute;
		top: -13px;
		left: 47px;
		@extend %transition;
	}
	.inner {
		h4 {
			&.title {
				font-size: 18px;
				font-weight: 700;
			}
		}
		p {
			font-size: 18px;
			margin-bottom: 0;
			color: rgba(29, 29, 36, 0.75);
			@media #{$lg-layout} {
				font-size: 16px;
			}
			@media #{$md-layout} {
				font-size: 16px;
			}
			@media #{$sm-layout} {
				font-size: 16px;
			}
			br {
				@media #{$lg-layout} {
					display: none;
				}

				@media #{$md-layout} {
					display: none;
				}

				@media #{$sm-layout} {
					display: none;
				}
			}
			a {
				color: rgba(29, 29, 36, 0.75);
				@extend %transition;
				&:hover {
					color: $theme-color;
				}
			}
		}
	}
	&:hover {
		.icon {
			background: $theme-color;
			border-color: $theme-color;
			color: #fff;
		}
	}
}

input,
textarea {
	@extend %transition;
}

.blog-comment-form .inner .rnform-group input:focus,
.blog-comment-form .inner .rnform-group textarea:focus {
	border-color: $theme-color;
}

.status {
	border: 2px solid #bf113e;
	border-radius: 6px;
	padding: 10px;
	text-align: center;
	font-size: 15px;
	margin-bottom: 20px;
	color: #989ba6;
	padding-left: 30px;
	padding-right: 20px;

	> span {
		font-size: 32px;
		font-weight: 400;
		float: left;
		color: #f9004d;
		line-height: 50px;

		> svg {
			stroke-width: 1 !important;
		}
	}
}
